import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { GtmModule } from 'ngx-gtm';
import { environment } from '../environments/environment';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { HttpClientModule } from '@angular/common/http';
//import { DeviceDetectorModule } from 'ngx-device-detector';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
  	BrowserModule,
    //IonicStepperModule,
  	IonicModule.forRoot(), 
  	AppRoutingModule,
  	GtmModule.forRoot({
      gtmId: environment.gtagcode,
      isEnabled: true
    }),
    HttpClientModule,
  ],
  providers: [
    { 
    	provide: RouteReuseStrategy, 
    	useClass: IonicRouteStrategy,

    },
    Geolocation
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

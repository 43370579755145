import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import(
      './index/index.module'
    ).then(m => m.IndexPageModule)
  },
  { 
    path: 'index', loadChildren: () => import(
      './index/index.module'
    ).then(m => m.IndexPageModule) 
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'ingresa-patente', loadChildren: () => import(
      './ingresa-patente/ingresa-patente.module'
    ).then( m => m.IngresaPatentePageModule)
  },
  {
    path: 'informacion-vehiculo/:patente', loadChildren: () => import('./informacion-vehiuculo/informacion-vehiculo.module').then( m => m.InformacionVehiculoPageModule)
  },
  {
    path: 'inspeccion',
    loadChildren: () => import('./inspeccion/inspeccion.module').then( m => m.InspeccionPageModule)
  },
  {
    path: 'danios',
    loadChildren: () => import('./danios/danios.module').then( m => m.DaniosPageModule)
  },
  { 
    path: 'fin', loadChildren: () => import(
      './fin/fin.module'
    ).then(m => m.FinPageModule) 
  },
  {
    path: 'imagen-danios',
    loadChildren: () => import('./imagen-danios/imagen-danios.module').then( m => m.ImagenDaniosPageModule)
  },
  { 
    path: 'flujo-principal', loadChildren: () => import(
      './flujo/flujo.module'
    ).then(m => m.FlujoPageModule) 
  },
  { 
    path: '**', loadChildren: () => import(
      './enlase-nodisponible/enlase-nodisponible.module'
    ).then(m => m.EnlaseNodisponiblePageModule) 
  },
  
  
];
@NgModule({
  imports: [
    RouterModule.forRoot(
      routes, { 
        preloadingStrategy: PreloadAllModules, 
        useHash: false  
      }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }